<template>
  <div class="container-fluid feeds">
    <div class="col-md-3 hidden-xs hidden-sm"></div>
    <div class="col-md-6 col-sm-12">
      <div class="profile">
        <div class="avatar">
          <span class="icon"><i class="icofont-user-alt-5"></i></span>
          <span>{{ (user) ? user.phone : 0 }}</span>
          <p></p>
          <!-- <span @click="refreshBalance" class="refresh-balance">{{ $t('header.refresh-balance') }}</span> -->
        </div>
        <div class="amounts">
          <div class="balance">
            <span>
              <i class="icofont-ui-clip-board"></i>
            </span>
            <div class="">
              <span>{{ $t('header.balance') }}</span>
              <p> {{ currency }} {{ (user) ? Number(user.account.main).toLocaleString() : 0 }}</p>
            </div>
          </div>
          <div class="bonus">
            <span>
              <i class="icofont-bootstrap"></i>
            </span>
            <div class="">
              <span>{{ $t('header.bonus') }}</span>
              <p>{{ currency }} {{ (user) ? Number(user.account.bonus).toLocaleString() : 0 }}</p>
            </div>
          </div>
        </div>
        <!-- deposit -->
        <h4  v-if="user.iso != 'BI'">{{ $t('deposit.header') }}</h4>
        <h4  v-if="user.iso == 'BI'">{{ $t('header.deposit') }}</h4>
        <div class="deposits">       
          <template v-if="user.iso == 'BI'">
            <div class="text-left pd pay">
              <span @click="changeType('lumicash')">Lumicash</span>
            </div>
            
            <div class="error-div pd text-left">
              <span v-if="amount < 500" class="text-orange">500 BIF {{  $t('deposit.min-length-error') }} &nbsp;</span>                     
              <span v-if="voucher === '' && lumicash" class="text-orange">{{ $t('deposit.voucher') }}</span> 
              <span v-if="voucher === '' && lumicash" class="text-orange">({{ $t('lumicash.li-8')}})</span>
              <span v-if="amount > 1000000 && lumicash && voucher !== '' " class="text-danger">100,000 BIF {{ $t('swal.lumicash-max-deposit') }}</span>
              <span v-if="amount >= 500  && !lumicash" class="text-orange">{{ message }}</span>
              <span v-if="amount >= 500 && amount <=1000000  && lumicash && voucher !== ''" class="text-orange">{{ message }}</span>
            </div>
            <div class="deposit-badge">
              <span class="badge bg-blue" @click="updateAmount('deposit',500)">+500</span>
              <span class="badge bg-blue" @click="updateAmount('deposit',1000)">+1000</span>
              <span class="badge bg-blue" @click="updateAmount('deposit',5000)">+5000</span>
              <span class="badge bg-blue" @click="updateAmount('deposit',10000)">+10000</span>
              <span class="badge bg-blue" @click="updateAmount('deposit',50000)">+50000</span> 
            </div>
            <div class="form-group pad">
              <input type="text" class="form-control deposit lumicash" id="voucher" name="voucher" v-model="voucher" v-if="lumicash" :placeholder="$i18n.t('lumicash.li-1-1')" required>
              <input type="text" class="form-control deposit" id="amount" name="amount" v-model="amount" placeholder="500">
            </div>
            <button class="btn bg-orange" :disabled="amount < 500 || amount > 1000000 || (voucher === '')" v-if="provider_type=='lumicash'" @click="makeDeposit_">{{ $t('header.deposit') }}</button>
          </template>
          <template v-else>
            <div class="img-grid">
              <img v-if="user.iso == 'KE'" :src="`${publicPath}images/logos/mpesa.png`" alt="Logo" class="img-logo" @click="deposit">
              <img v-if="user.iso == 'KE'" :src="`${publicPath}images/logos/astropay.png`" alt="Logo" class="img-logo" @click="deposit">
              <img v-if="user.iso == 'CM' || user.iso == 'BJ' || user.iso == 'ZM' || user.iso == 'CI' || user.iso == 'GH' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/mtn.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'RW' || user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'ZM' || user.iso == 'CD' || user.iso == 'UG'" :src="`${publicPath}images/logos/airtel.png`" alt="Logo" class="img-logo" @click="deposit" />        
              <img v-if="user.iso == 'CI'" :src="`${publicPath}images/logos/moov.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'CI' || user.iso == 'CM'" :src="`${publicPath}images/logos/orange.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'ZM' || user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'CM' ||  user.iso == 'KE' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/mastercard.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'ZM' || user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'CM' ||  user.iso == 'KE' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/visa.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'TZ'" :src="`${publicPath}images/logos/tigo.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'TZ' || user.iso == 'GH'" :src="`${publicPath}images/logos/vodacom.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso == 'ZM'" :src="`${publicPath}images/logos/zamtel.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/bitcoin.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/ethereum.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/litecoin.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/ripple.png`" alt="Logo" class="img-logo" @click="deposit" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/usdt.png`" alt="Logo" class="img-logo" @click="deposit" >
            </div>
            <template v-if="type == 'virtual_pay'">
              <div class="form-group">
                <input type="text" class="form-control deposit" id="amount" name="amount" v-model="amount" placeholder="500">
                <button  class="btn bg-orange" @click="makeVirtualpayDeposit">{{ $t('deposit.header') }}</button>
              </div>
            </template>
          </template>
        </div>

        <!-- withdraw -->
        <h4>{{ $t('withdraw.header') }}</h4>
        <div class="deposits">
          
          <template v-if="user.iso == 'BI'">
            <div class="text-left pd pay">
              <span v-if="user.iso == 'BI'">Lumicash</span>
            </div>
            <div class="deposit-badge">
              <span class="badge bg-blue" @click="updateAmount('deposits',500)">+500</span>
              <span class="badge bg-blue" @click="updateAmount('deposits',1000)">+1000</span>
              <span class="badge bg-blue" @click="updateAmount('deposits',2000)">+5000</span>
              <span class="badge bg-blue" @click="updateAmount('deposits',10000)">+10000</span>
              <span class="badge bg-blue" @click="updateAmount('deposits',20000)">+50000</span>
            </div>
            <div class="form-group">
              <input type="text" class="form-control deposit" v-model="wamount">
              <span v-if="wamount < 500" class="text-orange"><em>{{ this.$i18n.t('deposit.min-length-error') }}</em></span>
            </div>
            <button class="btn bg-orange" @click="withdrawal()">{{ $t('withdraw.header') }}</button>
          </template>
          <template v-else>
            <div class="img-grid">
              <img v-if="user.iso == 'KE'" :src="`${publicPath}images/logos/mpesa.png`" alt="Logo" class="img-logo" @click="withdraw__">
              <img v-if="user.iso == 'KE'" :src="`${publicPath}images/logos/astropay.png`" alt="Logo" class="img-logo" @click="withdraw__">
              <img v-if="user.iso == 'CM' || user.iso == 'BJ' || user.iso == 'ZM' || user.iso == 'CI' || user.iso == 'GH' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/mtn.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'ZM' || user.iso == 'CD' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/airtel.png`" alt="Logo" class="img-logo" @click="withdraw__" />
              <img v-if="user.iso == 'CI'" :src="`${publicPath}images/logos/moov.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              
              <img v-if="user.iso == 'CI' || user.iso == 'CM'" :src="`${publicPath}images/logos/orange.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso == 'ZM' || user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'CM' ||  user.iso == 'KE' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/mastercard.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso == 'ZM' || user.iso == 'TZ' || user.iso == 'GH' || user.iso == 'CM' ||  user.iso == 'KE' || user.iso == 'UG' || user.iso == 'RW'" :src="`${publicPath}images/logos/visa.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso == 'TZ' || user.iso == 'GH'" :src="`${publicPath}images/logos/tigo.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso == 'TZ'" :src="`${publicPath}images/logos/vodacom.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if=" user.iso == 'ZM'" :src="`${publicPath}images/logos/zamtel.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/bitcoin.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/ethereum.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/litecoin.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/ripple.png`" alt="Logo" class="img-logo" @click="withdraw__" >
              <img v-if="user.iso != 'BI' && user.iso != 'UG' && user.iso != 'RW'" :src="`${publicPath}images/logos/usdt.png`" alt="Logo" class="img-logo" @click="withdraw__" >
            </div>
          </template>
        </div>

        <div class="deposits">
          <h4>{{ $t('footer.support') }}</h4>
          <ul class="list-unstyled">
            <li v-if="geo && geo.country_code2 == 'BI'"><i class="fa fa-question-circle" aria-hidden="true"></i> {{'\xa0'}} {{ $t('footer.how-to-play1') }}</li>
            <li v-if="geo && geo.country_code2 == 'BI'"><i class="fa fa-phone" aria-hidden="true"></i> {{'\xa0'}} {{ $t('footer.service-client') }}</li>
            <li v-if="geo && geo.country_code2 == 'BI'"><i class="fa fa-whatsapp" aria-hidden="true"></i> {{'\xa0'}} {{ $t('footer.whatsapp') }}</li>

            <li v-if="geo && geo.country_code2 != 'BI'"><i class="fa fa-phone" aria-hidden="true"></i> {{ $t('footer.int_cc') }}</li>
            <li v-if="geo && geo.country_code2 != 'BI'"><i class="fa fa-whatsapp" aria-hidden="true"></i> {{ $t('footer.int_w') }}</li>
            <li v-if="geo && geo.country_code2 != 'BI'">{{ $t('footer.int_e') }}</li>
          </ul>
          <strong><a @click="logout" class="signout">{{ $t('header.logout') }}</a></strong>
        </div>

        <!-- astropay withdrawal modal -->
        <modal name="astropay" :width="300" height="auto" :adaptive="true">
          <div class="text-center bg-im">
            <img src="/images/astropay.webp" alt="Lumicash" title="Lumicash" style="max-width: 100px;"/>
          </div>
          <div class="error-div ">
            {{ this.$i18n.t('betslip.amount') }} (Min. {{ currency + ' ' + rate }})
          </div>
          <div class="form-group pad" >
            <span v-if="amount$ < parseInt(rate)" class="text-danger"><b>{{ currency + ' ' + rate }} and above </b></span>
            <input type="text" class="form-control" id="amount" name="amount" v-model="amount$" placeholder="$1">
          </div>
          <div class="text-center flexx">
            <button class="btn btn-primary btn-sm" :disabled="amount$ < parseInt(rate)" @click="makeAstroayDeposit">{{ $t('header.deposit') }}</button>
            <button class="btn btn-danger btn-sm" @click="cancelDeposit_">{{ $t('betslip.cancel') }}</button>
          </div>
        </modal>

        <!-- astropay withdrawal modal -->
        <modal name="withdraw" :width="300" height="auto" :adaptive="true">
          <div class="text-center bg-im">
            <img src="/images/astropay.webp" alt="Lumicash" title="Lumicash" style="max-width: 100px;"/>
          </div>
          <div class="error-div ">
            {{ this.$i18n.t('betslip.amount') }}  (Min. {{ currency + ' ' + rate }})
          </div>
          <div class="form-group pad" >
            <span v-if="wamount < parseInt(rate)" class="text-danger"><b>{{ currency + ' ' + rate }} {{ this.$i18n.t('deposit.min-length-error') }}</b></span>
            <input type="text" class="form-control" id="amount" name="amount" v-model="wamount" placeholder="$1">
          </div>
          <div class="text-center flexx">
            <button class="btn btn-primary btn-sm" :disabled="wamount < parseInt(rate)" @click="withdrawal()">{{ $t('withdraw.header') }}</button>
            <button class="btn btn-danger btn-sm" @click="cancelWithdraw__">{{ $t('betslip.cancel') }}</button>
          </div>
        </modal>

      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from "vuex";
import Auth from "@/services/auth.js";
import { astropayDialog } from "@/services/astropay.js";
import countryToCurrency from "country-to-currency";

export default {
  data () {
    return {
      publicPath: process.env.BASE_URL,
      show: false,
      chk:false,
      lumicash: true,
      provider_type: "lumicash",
      voucher: "",
      amount: 500,
      amount$:0,
      message: this.$i18n.t('deposit.submit'),
      wprovider:'lumicash',
      wamount:500,
      type:'',
      astro:['KE','UG','TZ','CD','RW','BI','ZM','CM','BJ','CI','GH'],
      wtype:'',
      option: {
        country:'',
        currency:'',
        provider: 'astropay',
        phone:''
      },
      virtual: {
        success:false,
        mid:'',
        amount:0,
        currency:'',
        apikey:'',
        mobile:'',
        pkey:'',
        requestId:'',
        checksum:'',
      }
    }
  },
  computed:{
    ...mapGetters({
      account: 'profile',
      user:'user',
      rates:'rates',
      geo:'geo',
    }),
    currency() {
      return (this.astro.includes(this.user.iso)) ? countryToCurrency[this.user.iso] : 'USD'
    },
    rate() {
      return (this.astro.includes(this.user.iso)) ?  this.rates[countryToCurrency[this.user.iso]].toFixed(0) : 1
    }
  },
  mounted() {
    this.refresh_balance();
    this.showTab();
    this.amount$ = (this.astro.includes(this.user.iso)) ? this.rates[countryToCurrency[this.user.iso]].toFixed(0) : 1
  },
  methods: {
    ...mapActions({
      auth_logout: 'logout_user',
      refresh_balance: 'get_refresh_balance',
      set_casino: 'set_casino'
    }),
    logout: function() {
      this.$swal({
        icon:'warning',
        title: this.$i18n.t('header.logout'),        
        showCancelButton:true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$i18n.t('swal.cancel'),
        confirmButtonText: this.$i18n.t('header.logout')
      }).then((result) => {
        if(result.value) {
            this.auth_logout().then( () => {
                if (this.$route.path != '/') {
                    this.$router.push("/");
                }
            })   
        }
      })
    }, 
    makeDeposit_: function () {
      this.message = this.$i18n.t('withdraw.li-5')
      let deposit = {};

      deposit.amount = this.amount;
      deposit.phone = this.user.phone;
      deposit.provider = this.provider_type;
      deposit.voucher = (this.provider_type === "lumicash") ? this.voucher : "";

      Auth.makeDeposit(deposit).then(data => {
        // reset defaults
        this.$modal.hide("deposit")
        this.provider_type = 'lumicash'
        this.amount = 500
        this.voucher = ''
        this.message = this.$i18n.t('deposit.submit')

        if (data.success) {
          if(data.status != 'failed') {
            this.refresh_balance()
            this.$swal.fire(
              this.$i18n.t('deposit.deposit-success'),
              '',                                    
              'success'
            );
          } else {
            if(data.message == 'Voucher Code for is invalid or TimeOut') {
              this.$swal.fire(
                this.$i18n.t('deposit.deposit-error'),   
                '',                                    
                'error'
              );
            } else {
              this.$swal.fire(
                data.message,
                'Oops...',
                'info'
              );
            }
          }
        }
        else if (data.statusCode == 301) {
          this.$swal.fire(
              data.message,
              'Oops...',
              'info'
          );
        }
        else {
          this.$swal.fire(
              data.message,
              '',
              'info'
          );
        }
      }).catch( err => console.log(err) )
    },
    provider: function(e) {
      var data = e.target.value;
      this.lumicash = (data === "lumicash") ? true : false;
      this.provider_type = data
    },
    withdrawal: function() {
      // var provider = document.querySelector('input[name="provider"]:checked').value
      let deposit = {};
      deposit.amount = parseInt(this.wamount);
      deposit.phone = this.user.phone;
      deposit.provider = this.wprovider;

      if(this.wprovider == 'astropay') {
        deposit.country = this.user.iso;
        deposit.currency = countryToCurrency[this.user.iso];
      }

      if(!this.chk)
      {
        if(this.user.iso == 'BI') {
          if (this.wamount < 500) {
            this.chk = false;
            this.$swal.fire(this.rate + ' ' + this.$i18n.t('deposit.min-length-error'));
            return;
          }
          else if(this.user.main < 500)
          {
            this.chk = false;
            this.$swal.fire(this.$i18n.t('withdraw.error-encountered'));
            return;
          }
          // else if (this.wamount > 5000000) 
          // {             
          //   this.chk = false;                                                                                  
          //   this.$swal.fire("Max amount allowed is 5million BIF");
          //   return;
          // }
        } else {
           if (this.wamount < parseInt(this.rate) ) {
            this.chk = false;
            this.$swal.fire(this.rate + ' ' + this.$i18n.t('deposit.min-length-error'));
            return;
          }
          else if(this.user.account.main < parseInt(this.rate))
          {
            this.chk = false;
            this.$swal.fire(this.$i18n.t('withdraw.error-encountered'));
            return;
          }
          // else if (this.wamount > 1000000) 
          // {             
          //   this.chk = false;                                                                                  
          //   this.$swal.fire("Max amount allowed is 100,000");
          //   return;
          // }
        }

        
          
        this.chk = true                                                                                    
        Auth.makeWithdrawal(deposit).then(data => {
          if (data.success) {
            this.refresh_balance()
            this.chk = false;
            if(data.success)
            {
              this.wamount = '';
              this.$swal.fire(
                this.$i18n.t('deposit.withdrawal-success'),
                '',
                'success'
              ); 
            } else {
              this.chk = false;
              this.$swal.fire(
                  this.$i18n.t('deposit.error-encountered'),
                  '',
                  'error'
              ); 
            }
          }
          else {
            this.chk = false;
            this.$swal.fire(
                data.message,
                '',
                'info'
            );
          }
        },
        () => {                                                    
          this.chk = false;
          this.$swal.fire(
              'Réessayez après 5 minutes',
              '',
              'error'
          );                                
        }).catch( err => console.log(err) )
        
      }
      else
      {
        this.$swal.fire(this.translate.instant('withdraw.li-5'));
        return false;
      }
    },
    updateAmount: function(type,amount) {
      if (type == 'deposit') {
        this.amount = amount;
      } else {
        this.wamount = amount;
      }
    },
    deposit: function() {
      if(this.user.iso != 'UG' && this.user.iso != 'RW') {
        this.$modal.show('astropay')
      }
    },
    cancelDeposit_: function () {
      this.$modal.hide("astropay")
      this.amount$ = this.rate;
    },
    withdraw__: function() {
      this.wprovider = 'astropay';
      if(this.user.iso != 'UG' && this.user.iso != 'RW') {
        this.$modal.show('withdraw')
      }
    },
    cancelWithdraw__: function () {
      this.$modal.hide("withdraw")
      this.amount$ = 0;
    },
    showTab() {
      if(this.user.iso == 'BI') {
        this.type = 'lumicash'
        this.wtype = 'lumicash'
      } else {
        this.type = 'astropay'
        this.wtype = 'astropay'
        this.wamount = (this.astro.includes(this.user.iso)) ? this.rates[countryToCurrency[this.user.iso]].toFixed(0) : 1;
      }
    },
    refreshBalance: async function () {
      await this.refresh_balance()
    },
    changeType: function(arg) {
      this.type = arg;
    },
    makeAstroayDeposit: function () {
      this.option.country = this.user.iso;
      this.option.phone = this.user.phone;
      this.option.currency = (this.astro.includes(this.user.iso)) ? countryToCurrency[this.user.iso].toUpperCase() : 'USD';
      Auth.getAstropayUrl(this.option,this.amount$).then( res => {
        astropayDialog(res);
      })
    },
    makeAstroayWithdrawal: function () {
      this.option.country = this.user.iso;
      this.option.currency = countryToCurrency[this.user.iso];
      Auth.getAstropayUrl(this.option,this.amount).then( res => {
        astropayDialog(res);
      })
    },
    makeVirtualpayDeposit: async function() {
      let option = {
        amount: this.amount,
        phone: this.user.phone,
        currency: countryToCurrency[this.user.iso]
      }
      await Auth.getVirtualUrl(option).then( res => {
        this.virtual.success = true;

        this.virtual.mid = res.data.mid;
        this.virtual.amount = res.data.amount;
        this.virtual.currency = res.data.currency;
        this.virtual.apikey = res.data.key;
        this.virtual.pkey = res.data.pkey;
        this.virtual.requestId = res.data.reqId;
        this.virtual.checksum = res.data.sign;
        this.virtual.mobile = res.data.mobile;
        this.virtual.country = this.user.iso;
      })

      Auth.postVirtualPay(this.virtual).then( res => {
        console.log(res);
      })
    },
    makeVirtualpayRequest: async function() {
      console.log(this.virtual);

      await Auth.postVirtualPay(this.virtual).then( res => {
        console.log(res);
      })
    },
    
  }
}
</script>
<style scoped>
.profile .deposits {
  padding: 30px 20px;
}
  .icon i {
    margin-right: 0px;
  }
  .profile {
    padding: 10px 0px;
  }
  .pd {
    padding: 8px 0px;
    background-color: var(--bg-dark-medium);
    text-align: left;
  }
  .pd.pay {
    display: flex;
    gap:10px
  }
  .pay span.select {
    color: var(--a-green);
  }
  .deposit-badge .badge {
    display: inline-block;
    padding: 6px;
    margin-right: 5px;
    cursor:pointer
  }
  .img-grid {
    display: grid;
    align-items: center;
    
  }
  .img-grid .img-logo {
    max-width: 100px;
    cursor: pointer;
  }
  .bg-im {
    background-color: var(--bg-dark-medium);
    padding: 10px 5px;
  }
  h4 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  
  @media(max-width:767px) {
    .profile .amounts .balance > span, .profile .amounts .bonus > span {
      font-size: 35px;
      margin-right: -20px;
      margin-top: -8px;
    }
    .img-grid {
      grid-template-columns: repeat(3,1fr);
      row-gap: 20px;
      column-gap: 15px;
    }
  }

  @media(min-width:992px) {
    .img-grid {
      grid-template-columns: repeat(5,1fr) !important;
      row-gap: 20px;
      column-gap: 10px;
    }
  }

  @media(min-width:768px) {
    .img-grid {
      grid-template-columns: repeat(4,1fr);
      row-gap: 20px;
      column-gap: 15px;
    }
}
</style>