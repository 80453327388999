import AstropaySDK, { ConsoleLogger } from  '@astropay/astropay-sdk';
const APP_ID = "rOVcC7By8GqU8giD";

export async function astropayDialog(params) {
    const config = {
        environment: 'production',
        logger: new ConsoleLogger(),
    };
    AstropaySDK.init(APP_ID,config)

    const ASTROPAY_RESPONSE = {  
        "merchant_deposit_id":  params.data.merchant_deposit_id,         
        "deposit_external_id": params.data.deposit_external_id,  
        "status":  params.data.status,  
        "url":  params.data.url,  
        "type":  params.data.type  
    };
    
    // Show the DEPOSIT client
    await AstropaySDK.deposit(ASTROPAY_RESPONSE);
}